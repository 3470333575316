/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Grid, Flex, Box, Heading, Text } from 'theme-ui';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const HairCareGuide = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `GuidesCare.Title`,
          message: `Hair Care Guide`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `GuidesCare.MetaDescription`,
            message: `Hair Extensions Care Guide`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `GuidesCare.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `GuidesCare.BreadcrumbsTitle`,
              message: `Hair Care Guide`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Grid
        columns={[1, 2]}
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Box>
          <Heading as="h1" sx={{ fontSize: [4, 5] }} mb={[2, 3]}>
            <Trans id="GuidesCare.Header">Hair Care Guide</Trans>
          </Heading>
          <Trans id="GuidesCare.Description">
            <Text as="p" my={[2, 3]}>
              As Kisscurls monotapes are made of natural hair, please take care
              of your extensions as you would take care of your own hair.
            </Text>
            <Text as="p" my={[2, 3]}>
              The hair went through a preparation process to ensure the highest
              quality and guarantee the longest wear possible. Each pack of our
              hair is unique. The lifespan of your Kisscurls hair extensions can
              last many months with proper care.
            </Text>
          </Trans>
        </Box>
        <Box>
          <StaticImage
            alt={t({
              id: `GuidesCare.HeroImageImageAlt`,
              message: `Hair extensions care guide`,
            })}
            sx={{ maxHeight: 300 }}
            placeholder="blurred"
            layout="constrained"
            src="../../images/guides/hair-care-guide.jpg"
          />
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.WashingTitle">Washing</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.WashingDescription">
              We recommend using sulfate and paraben-free shampoo to keep the
              color of your hair extensions longer. Use conditioner and leave-in
              conditioner after every washing Use a deep conditioner or mask
              once a week Do not apply conditioner, mask, and other care
              products on roots to avoid slippage We recommend washing your hair
              2-3 times per week. If needed, we recommend using dry shampoo
              between washings avoid washing every day.
            </Trans>
          </Text>
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.DryingTitle">Heat and drying</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.DryingDescription">
              Using excessive heat on your hair extensions will reduce their
              lifespan. As monotapes made of natural hair, they can be damaged
              with too much heat. Usage of special thermal protection spray
              before heat is necessary. Use a hairdryer on cold or warm air
              mode. Do not apply extended blowdryer heat or direct styling tool
              heat to the hair extension bond. The maximum temperature of your
              styling tool should be 190C.
            </Trans>
          </Text>
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.BrushingTitle">Brushing</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.BrushingDescription">
              Brush your hair 3-5 times a day. Avoid excessive brushing. Please
              use a special tangle teezer brush to avoid tangling. Brush your
              hair gently from the ends, working your way to root. Do not brush
              wet hair.
            </Trans>
          </Text>
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.ColoringTitle">Coloring</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.ColoringDescription">
              We cannot guarantee the quality of our extensions after any dye,
              toner, bleach, perm, keratin, or straightening treatment is
              performed. If you wish to use such products, always try a test
              strip before proceeding to make sure you are satisfied with the
              results. The use of chemicals may damage the extensions. Always
              see a licensed professional for any services you wish to perform
              on your extensions.
            </Trans>
          </Text>
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.SleepingTitle">Sleeping</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.SleepingDescription">
              Before sleeping put your hair in a low braid or ponytail to avoid
              tangles. Never go to bed with your hair wet.
            </Trans>
          </Text>
        </Box>
        <Box>
          <Heading
            as="h2"
            color="primary"
            mt={[4]}
            mb={[3]}
            sx={{ fontSize: [3, 4] }}
          >
            <Trans id="GuidesCare.SwimmingTitle">Swimming and UV rays</Trans>
          </Heading>
          <Text as="p" mb={[2]}>
            <Trans id="GuidesCare.SwimmingDescription">
              We do NOT recommend swimming with your extensions. We recommend
              avoiding salt water, chlorinated water, and excessive exposure to
              UV rays, as these may damage your extensions, diminish their
              color. Keep your hair under a hat to protect your hair color and
              excess dryness.
            </Trans>
          </Text>
        </Box>
      </Grid>
    </>
  );
};

export const HairCareGuidePageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default HairCareGuide;
